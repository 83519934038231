import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public basemapType: string = 'streets';
  public mapZoomLevel: number = 14;
  public isMapLoaded: boolean = false;

  public mapLoadedEvent(status: boolean): void {
    this.isMapLoaded = status;
  }
}
