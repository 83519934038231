import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EsriMapComponent } from './components/map/esri-map.component';
import { ESRI_ROUTES } from './esri.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(ESRI_ROUTES, { useHash: false })
  ],
  declarations: [
    EsriMapComponent
  ],
  providers: [

  ],
  exports: [
    EsriMapComponent
  ]
})
export class EsriMapModule { }
