export class MapPoint {
  mapPointPinInformationLines: Array<MapPointPinInformationLine>;
  meterNumber: string;
  route: string;
  accountNumber: string;
  sequenceNumber: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: string;
  longitude: any;
  latitude: any;

  fullAccountNumber(data: MapPoint): string {
    return `${data.route}-${data.accountNumber}-${data.sequenceNumber}`;
  }

  fullAddress(data: MapPoint): string {
    return `${data.streetNumber} ${data.streetName}, ${data.city}, ${data.state} ${data.zip}`;
  }
}

export class FieldInfo {
  fieldName: string;
  label: string;
}

export class MapPointPinInformationLine {
  attribute: string;
  labelDescription: string;
  informationValue: string;
}
