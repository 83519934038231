import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCase', pure: false })
export class CamelCase implements PipeTransform {
  transform(input: string): string {
    return input
      .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
  }
}
