import { Routes } from '@angular/router';

import { EsriMapComponent } from './esri/components/map/esri-map.component';
import { ESRI_ROUTES } from './esri/esri.routing';

export const APP_ROUTES: Routes = [
  {
    path: 'map',
    component: EsriMapComponent,
  },
  ...ESRI_ROUTES,
  {
    path: '**',
    redirectTo: '/map'
  }
];
