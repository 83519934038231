import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routing';
import { EsriMapModule } from './esri/esri.module';
import { CamelCase } from './esri/pipes/camel-case.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CamelCase
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    EsriMapModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: false })
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
