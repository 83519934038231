import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loadModules } from 'esri-loader';
import { Config } from 'src/app/app.config';
import { FieldInfo, MapPoint, MapPointPinInformationLine } from '../../models/map-point.type';
import { CamelCase } from '../../pipes/camel-case.pipe';
import esri = __esri;
import { LatLng } from '../../models/lat-lng.type';

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  providers: [CamelCase],
  styleUrls: ['./esri-map.component.scss']
})

export class EsriMapComponent implements OnInit {

  @Output() mapLoaded = new EventEmitter<boolean>();
  @ViewChild('map') private mapViewEl: ElementRef;

  /**
   * @private _zoom sets map zoom
   * @private _center sets map center
   * @private _basemap sets type of map
   */
  private _zoom: number = 15;
  private _basemap: string = 'streets';

  public centerLongitude: number;
  public centerLatitude: number;

  public utilityData: MapPoint = null;
  public searchData: Array<MapPoint> = new Array();

  public esriMap: esri.Map = null;
  public esriMapView: esri.MapView = null;
  public locator: esri.Locator = null;
  public point: esri.Point = null;
  public mapPoint: MapPoint = new MapPoint;
  public latLng: Array<LatLng>;
  public red: number = 255;
  public green: number = 255;
  public blue: number = 255;

  public hideSatelliteButton: boolean = false;

  @Input()
  set zoom(zoom: number) {
    this._zoom = zoom;
  }
  get zoom(): number {
    return this._zoom;
  }

  @Input()
  set basemap(basemap: string) {
    this._basemap = basemap;
  }
  get basemap(): string {
    return this._basemap;
  }

  constructor(
    private camelCase: CamelCase,
    private route: ActivatedRoute
  ) { }

  public async ngOnInit(): Promise<any> {

    await this.queryParamResults()
      .then(() => {
        Promise.all([
          this.getUtilityDataCoordinates(),
          this.getSearchDataCoordinates()
        ]).then(() => {
          this.loadMap(this.hideSatelliteButton);
        });
      });
  }

  public async queryParamResults(): Promise<any> {

    const promise = new Promise((resolve) => {
      this.route.queryParams.subscribe(params => {
        if (params['latLng']) {
          this.latLng = JSON.parse(params['latLng']);
          this.centerLatitude = this.latLng[0].latitude;
          this.centerLongitude = this.latLng[0].longitude;
        }
        if (params['utilityData']) {
          this.utilityData = new MapPoint();
          this.utilityData = JSON.parse(params['utilityData']);
        }
        if (params['searchData']) {
          this.searchData = JSON.parse(params['searchData']);
        }
        if (params['zoom']) {
          this.zoom = JSON.parse(params['zoom']);
        }
        if (params['hideSatelliteButton']) {
          this.hideSatelliteButton = JSON.parse(params['hideSatelliteButton']);
        }
        resolve();
      });
    });

    return await promise;
  }

  public async getUtilityDataCoordinates(): Promise<any> {

    if (this.utilityData != null) {
      try {
        const [Locator, Point] = await loadModules([
          'esri/tasks/Locator',
          'esri/geometry/Point'
        ]);

        const locator: esri.Locator = new Locator(Config.ARC_GIS_LOCATOR);
        const mapPoint: MapPoint = new MapPoint;

        const address = {
          'SingleLine': mapPoint.fullAddress(this.utilityData)
        };

        const param = {
          address: address,
          categories: ['Address'],
          countryCode: 'US',
          distance: 100,
          forStorage: false,
          location: new Point,
          magicKey: '',
          maxLocations: 10,
          outFields: ['Loc_name'],
          searchExtent: null
        }

        await locator.addressToLocations(param)
          .then((res: any) => {
            res.forEach((result: any) => {
              if (result.score > Config.ARC_GIS_LOCATOR_TOLERANCE) {
                this.utilityData.longitude = result.location.longitude;
                this.utilityData.latitude = result.location.latitude;

                if (this.searchData.length === 0) {
                  this.centerLongitude = result.location.longitude;
                  this.centerLatitude = result.location.latitude;
                }
              }
            });
          }, (err: any) => {
            console.error(err);
          }
          )

      } catch (err) {
        console.error(err);
      }
    }
  }

  public async getSearchDataCoordinates(): Promise<any> {

    if (this.searchData.length > 0) {
      try {
        const [Locator, Point] = await loadModules([
          'esri/tasks/Locator',
          'esri/geometry/Point'
        ]);

        const locator: esri.Locator = new Locator(Config.ARC_GIS_LOCATOR);
        const mapPoint: MapPoint = new MapPoint;

        const promise = new Promise((resolve, reject) => {
          this.searchData.forEach((addressLocation: any, index: number) => {

            const address = {
              'SingleLine': mapPoint.fullAddress(addressLocation)
            };

            const param = {
              address: address,
              categories: ['Address'],
              countryCode: 'US',
              distance: 100,
              forStorage: false,
              location: new Point(),
              magicKey: '',
              maxLocations: 10,
              outFields: ['Loc_name'],
              searchExtent: null
            };

            let longitudeMatch: number;
            let latitudeMatch: number;

            locator.addressToLocations(param)
              .then((res: any) => {
                  if (res[0].score > Config.ARC_GIS_LOCATOR_TOLERANCE) {
                    longitudeMatch = res[0].location.longitude;
                    latitudeMatch = res[0].location.latitude;
                  }
              }, (err: any) => {
                console.error(err);
              })
              .then(() => {
                if (index === 0) {
                  this.centerLongitude = longitudeMatch;
                  this.centerLatitude = latitudeMatch;
                }

                this.searchData[index].longitude = longitudeMatch;
                this.searchData[index].latitude = latitudeMatch;

                if ((index + 1) === this.searchData.length) {
                  resolve();
                }
              });
          });
        });

        return promise;

      } catch (err) {
        console.error(err);
      }
    }
  }

  public async loadMap(hideSatelliteButton?: boolean): Promise<any> {

    try {
      const [EsriMap, EsriMapView, BasemapToggle] = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/widgets/BasemapToggle'
      ]);

      const mapProperties: esri.MapProperties = {
        basemap: this._basemap
      };

      const map: esri.Map = new EsriMap(mapProperties);

      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewEl.nativeElement,
        center: [this.centerLongitude, this.centerLatitude],
        zoom: this._zoom,
        map: map
      };

      const mapView: esri.MapView = new EsriMapView(mapViewProperties);

      if (!hideSatelliteButton) {
        var toggle = new BasemapToggle({ view: mapView, nextBasemap: "hybrid" });
        mapView.ui.add(toggle, "top-right");
      }

      if (this.utilityData != null) {
        mapView.graphics.add(this.getUtilityDataPoint());
      }

      if (this.searchData.length > 0) {
        mapView.graphics.addMany(this.getSearchDataPoints());
      }

      if (this.latLng.length > 0) {
        this.latLng.forEach((point: any) => {
          this.red = this.red - 21;
          this.green = this.green - 11;
          this.blue = this.blue - 5;

          if (point == this.latLng[this.latLng.length-1]) {
            mapView.graphics.add(this.getLatLngPoints(point, this.red, this.green, this.blue, true));
          } else {
            mapView.graphics.add(this.getLatLngPoints(point, this.red, this.green, this.blue, false));
          }
        });

        this.red = 255;
        this.green = 255;
        this.blue = 255;

        for (var i = 0; i < this.latLng.length; i++) {
          this.red = this.red - 21;
          this.green = this.green - 11;
          this.blue = this.blue - 5;

          var polyline = {
            type: "polyline", // autocasts as new Polyline()
            paths: [
              [this.latLng[i].longitude, this.latLng[i].latitude],
              [this.latLng[i+1].longitude, this.latLng[i+1].latitude]
            ]
          };

          var lineSymbol = {
            type: "simple-line", // autocasts as SimpleLineSymbol()
            color: [this.red, this.green, this.blue],
            width: 2
          };

          var polylineGraphic: any = ({
            geometry: polyline,
            symbol: lineSymbol
          });

          mapView.graphics.add(polylineGraphic);
        };
      }

      mapView.when(() => {
        this.mapLoaded.emit(true);
      });
    } catch (err) {
      console.error(err);
    }
  }

  public getUtilityDataPoint(): any {

    const markerSymbol = {
      type: 'picture-marker',
      url: 'assets/home-pin.png',
      height: '30px',
      width: '25px'
    };

    const graphic: any = {
      type: 'point',
      longitude: this.utilityData.longitude,
      latitude: this.utilityData.latitude
    };

    const hasDetails = (!this.utilityData.mapPointPinInformationLines ? false : true);

    if (hasDetails) {
      const attribute: any = new Object;
      const fieldInfoCollection: any = new Array;

      this.utilityData.mapPointPinInformationLines.forEach((line: MapPointPinInformationLine) => {
        line.attribute = this.camelCase.transform(line.labelDescription);
        attribute[line.attribute] = line.informationValue;

        const fieldInfo: FieldInfo = {
          fieldName: line.attribute,
          label: line.labelDescription
        };
        fieldInfoCollection.push(fieldInfo);
      });

      return ({
        geometry: graphic,
        symbol: markerSymbol,
        attributes: attribute,
        popupTemplate: {
          title: 'Utility Information:',
          content: [
            {
              type: 'fields',
              fieldInfos: fieldInfoCollection
            }
          ]
        }
      });
    } else {
      return ({
        geometry: graphic,
        symbol: markerSymbol
      });
    }
  }

  public getSearchDataPoints(): Array<any> {

    const searchData: MapPoint = new MapPoint;
    const graphicCollection: Array<any> = new Array();

    try {
      const markerSymbol = {
        type: 'simple-marker',
        style: 'circle',
        color: [0, 118, 188],
        size: '15px',
        outline: {
          color: [0, 118, 188],
          width: 0
        }
      };

      this.searchData.forEach((point: MapPoint) => {
        const graphic: any = {
          type: 'point',
          longitude: point.longitude,
          latitude: point.latitude
        };

        const hasDetails = (!point.mapPointPinInformationLines ? false : true);

        let attribute: any = new Object;
        const fieldInfoCollection: any = new Array;

        if (hasDetails) {
          point.mapPointPinInformationLines.forEach((line: MapPointPinInformationLine) => {
            line.attribute = this.camelCase.transform(line.labelDescription);
            attribute[line.attribute] = line.informationValue;

            const fieldInfo: FieldInfo = {
              fieldName: line.attribute,
              label: line.labelDescription
            };
            fieldInfoCollection.push(fieldInfo);
          });

          attribute.longitude = point.longitude;
          attribute.latitude = point.latitude;

          fieldInfoCollection.push(
            {
              fieldName: 'longitude',
              label: 'Longitude'
            }, {
              fieldName: 'latitude',
              label: 'Latitude'
            }
          );
        } else {
          attribute = {
            accountNumber: searchData.fullAccountNumber(point),
            address: searchData.fullAddress(point),
            meterNumber: point.meterNumber,
            longitude: point.longitude,
            latitude: point.latitude
          };
        }

        let pointGraphic: any = new Object;

        if (hasDetails) {
          pointGraphic = ({
            geometry: graphic,
            symbol: markerSymbol,
            attributes: attribute,
            popupTemplate: {
              title: 'Account Information:',
              content: [
                {
                  type: 'fields',
                  fieldInfos: fieldInfoCollection
                }
              ]
            }
          });
        } else {
          pointGraphic = ({
            geometry: graphic,
            symbol: markerSymbol,
            attributes: attribute,
            popupTemplate: {
              title: 'Account Information:',
              content: [{
                type: 'fields',
                fieldInfos: [{
                  fieldName: 'accountNumber',
                  label: 'Account Number'
                }, {
                  fieldName: 'address',
                  label: 'Address'
                }, {
                  fieldName: 'meterNumber',
                  label: 'Meter Number'
                }, {
                  fieldName: 'longitude',
                  label: 'Longitude'
                }, {
                  fieldName: 'latitude',
                  label: 'Latitude'
                }]
              }]
            }
          });
        }

        graphicCollection.push(pointGraphic);
      });
    } catch (err) {
      console.error(err);
    }

    return graphicCollection;
  }

  public getLatLngPoints(point: LatLng, red: number, green: number, blue: number, finalPoint: boolean): any {

    const markerSymbol = {
      type: 'simple-marker',
      style: 'circle',
      color: [red, green, blue],
      size: '20px',
      outline: {
        color: [0, 118, 188],
        width: 1
      }
    };

    const markerSymbolFinal = {
      type: 'simple-marker',
      style: 'circle',
      color: [64, 175, 73],
      size: '20px',
      outline: {
        color: [0, 118, 188],
        width: 1
      }
    };

    const graphic: any = {
      type: 'point',
      longitude: point.longitude,
      latitude: point.latitude
    };

    const hasDetails = (!point.dateCreated ? false : true);

    if (hasDetails) {
      let attribute: any = new Object;
      const fieldInfoCollection: any = new Array;

      attribute.dateCreated = point.dateCreated;

      const fieldInfo: FieldInfo = {
        fieldName: "dateCreated",
        label: "Date Created"
      };
      fieldInfoCollection.push(fieldInfo);

      if (finalPoint) {
        return ({
          geometry: graphic,
          symbol: markerSymbolFinal,
          attributes: attribute,
          popupTemplate: {
            title: 'Tracking Information:',
            content: [
              {
                type: 'fields',
                fieldInfos: fieldInfoCollection
              }
            ]
          }
        });
      } else {
        return ({
          geometry: graphic,
          symbol: markerSymbol,
          attributes: attribute,
          popupTemplate: {
            title: 'Tracking Information:',
            content: [
              {
                type: 'fields',
                fieldInfos: fieldInfoCollection
              }
            ]
          }
        });
      }
    }
  }
}
